export default {
    marker: {
        // turn on/off camera smoothing - ['true', 'false'] - default: false
        smooth: true,

        // number of matrices to smooth tracking over, more = smoother but slower follow - default: 5
        smoothCount: 5,

        // distance tolerance for smoothing, if smoothThreshold # of matrices are under tolerance, tracking will stay still - default: 0.01
        smoothTolerance: 0.01,

        // 	threshold for smoothing, will keep still unless enough matrices are over tolerance - default: 2
        smoothThreshold: 2,
    },
    smoke: {
        // smoke animation delay in seconds (default = 42)
        delay: 42
    },
    general: {
        // the timeout length for the whole animation sequence
        timeout: 37000,
        // time to start billboard crossfade
        billboardCrossfade: 16000,
        // time between falcon billboard disappearing and pig billboard appearing
        billboardGap: 2000,
        billboardScale: 0.5,
        // delay in milliseconds before the app is reloaded at the end of the animation
        replayDelay: 1500
    }
}