import { useEffect } from "react";
import config from "./config";

function ARScene() {
  useEffect(() => {
    const simulate = () => {
      const marker = document.getElementById('marker')
      const simulateMarker = document.getElementById('simulate-marker')

      while (marker.childNodes.length > 0) {
        const entity = marker.childNodes[0];
        var copy = entity.cloneNode();
        simulateMarker.appendChild(copy);
        entity.parentNode.removeChild(entity);
      }

      window.startAnim(true)
    }

    const onKeyPress = (e) => {
      if (e.key == 's') simulate()
    }

    window.addEventListener('keypress', onKeyPress)
    return () => window.removeEventListener('keypress', onKeyPress)
  }, [])

  useEffect(() => {
    const AFRAME = global.AFRAME;
    const THREE = global.THREE;

    AFRAME.registerComponent("cloak", {
      init: function () {
        let geometry = new THREE.RingGeometry(1.6375, 2000, 64);
        let material = new THREE.MeshBasicMaterial({
          colorWrite: false,
          color: "red",
        });
        let mesh = new THREE.Mesh(geometry, material);
        mesh.rotation.x = -Math.PI / 2;
        // mesh.scale.set(1.1, 1.1, 1.1)
        this.el.object3D.add(mesh);
      },
    });

    let animStarted = false;
    let markerFound = false;
    let firstTime = false;

    AFRAME.registerComponent("registerevents", {
      init: function () {
        let marker = this.el;
        marker.addEventListener("markerFound", function () {
          const rotation = marker.getAttribute("rotation");
          console.log('marker found')
          // this is debug code allowing us to study the inherent bug in AR.js where sometimes a marker is captured
          // wrongly and delivers the experience at an offset angle.
          // "restart on bad angular capture"
          // if(!firstTime && rotation.x > 120){
          //
          //
          //     //this forces a reload if marker is caught at too steep an angle
          //     return window.location.reload();
          //
          //     marker.setAttribute("enabled", false);
          //
          //     // alert("DISABLING MARKER FOR 1 SECOND")
          //
          //     window.setTimeout(() => {
          //         marker.setAttribute("enabled", true);
          //
          //     }, 1000)
          //
          //     return;
          // }
          // end of restart on bad capture debug
          firstTime = true;

          markerFound = true;

          window.setTimeout(() => {
            if (markerFound && !animStarted) {
              console.log('starting anim')
              startAnim();
            }
          }, 100);
        });

        marker.addEventListener("markerLost", function () {
          console.log('marker lost')
          markerFound = false;
        });
      },
    });

    let animStartTime = 0;
    let animTimeout = -1;

    function startAnim(force) {
      if (!force) {
        if (!window.onboardingComplete) {
          setTimeout(() => startAnim(force), 1000)
          return;
        }
        if (!markerFound) return;
        if (animStarted) return;
      }

      animStartTime = performance.now();
      animStarted = true;

      let cloakRing = document.getElementById("cloakRing");
      let pigs = document.getElementById("pigs");
      let falcons = document.getElementById("falcons");
      let ring = document.getElementById("ring");
      let logoAnimated = document.getElementById("logoAnimated");

      pigs.setAttribute("animation-mixer", { timeScale: 1 });
      falcons.setAttribute("animation-mixer", { timeScale: 1 });

      let pigsBillboard = document.getElementById("billboard-pigs");
      let falconsBillboard = document.getElementById("billboard-falcons");

      falconsBillboard.setAttribute("animation-mixer", { timeScale: 1 });
      falconsBillboard.setAttribute(
        "animation",
        "property: scale; to: 0.5 0.5 0.5; dur: 1000; easing: easeOutQuad; delay: 6000; loop: false"
      );

      setTimeout(() => {
        // switch over the visible billboard
        falconsBillboard.setAttribute(
          "animation",
          "property: scale; to: 0 0 0; dur: 1000; easing: easeOutQuad; delay: 0; loop: false"
        );

        pigsBillboard.setAttribute("animation-mixer", { timeScale: 1 });
        pigsBillboard.setAttribute(
          "animation",
          `property: scale; to: 0.5 0.5 0.5; dur: 1000; easing: easeOutQuad; delay: ${config.general.billboardGap}; loop: false`
        );

        setTimeout(() => {
          pigsBillboard.setAttribute(
            "animation",
            "property: scale; to: 0 0 0; dur: 1000; easing: easeOutQuad; delay: 0; loop: false"
          );  
        }, config.general.timeout - config.general.billboardCrossfade)
      }, config.general.billboardCrossfade)

      // ring.setAttribute("animation-mixer", { timeScale: 1 })
      ring.setAttribute(
        "animation",
        "property: scale; to: 1.145 1 1.145; dur: 1250; easing: linear; delay: 1000; loop: false"
      );
      cloakRing.setAttribute(
        "animation",
        "property: scale; to: 1 1 1; dur: 1250; easing: linear; delay: 1000; loop: false"
      );

      logoAnimated.setAttribute("animation-mixer", { timeScale: 1 });
      logoAnimated.setAttribute(
        "animation",
        "property: scale; to: 1 1 1; dur: 1250; easing: linear; delay: 1000; loop: false"
      );

      animTimeout = window.setTimeout(() => {
        ring.setAttribute(
          "animation",
          "property: scale; to: 0.01 0.01 0.01; dur: 1250; easing: linear; delay: 1004; loop: false"
        );
        logoAnimated.setAttribute(
          "animation",
          "property: scale; to: 0.01 0.01 0.01; dur: 1250; easing: linear; delay: 1004; loop: false"
        );
        cloakRing.setAttribute(
          "animation",
          "property: scale; to: 0.01 0.01 0.01; dur: 1250; easing: linear; delay: 1000; loop: false"
        );

        // replay UX
        window.setTimeout(() => {
          localStorage.setItem("finished", "1");
          if (!force) window.location.reload();
        }, config.general.replayDelay);
        // end of replay UX ***
      }, config.general.timeout);
    }
    window.startAnim = startAnim

    document.getElementById("arscene").innerHTML = `
            <a-scene
                device-orientation-permission-ui="enabled: false"
                embedded
                vr-mode-ui="enabled: false"
                renderer="colorManagement: true;"
                arjs="sourceType: webcam; debugUIEnabled: false; patternRatio: 0.67; "
            >
            
            <a-assets timeout="30000">       
                <a-asset-item id="half-sphere" src="/models/06/Halfsphere_offset3.glb" response-type="arraybuffer" timeout="20000"></a-asset-item>
                <a-asset-item id="ring-model" src="/models/06/ring_2.glb" response-type="arraybuffer" timeout="20000"></a-asset-item>
                <a-asset-item id="logoAnimated-model" src="/models/06/logoAnimated4_2.glb" response-type="arraybuffer" timeout="20000"></a-asset-item>
                <a-asset-item id="falcon-model" src="/models/06/Falcons.glb" response-type="arraybuffer" timeout="20000"></a-asset-item>
                <a-asset-item id="pig-model" src="/models/06/Pigs.glb" response-type="arraybuffer" timeout="20000"></a-asset-item>
                <a-asset-item id="billboard-pigs-model" src="/models/06/billboard_pigs.glb" response-type="arraybuffer" timeout="20000"></a-asset-item>
                <a-asset-item id="billboard-falcons-model" src="/models/06/billboard_falcons.glb" response-type="arraybuffer" timeout="20000"></a-asset-item>
                <!-- <img id="pig-description" src="/images/pigs.png" /> -->
                <!-- <img id="falcon-description" src="/images/falcons.png" /> -->
            </a-assets>
            
        <!--size="0.00" = size of the marker in meters: artoolkitmarker.size-->
        <!--marker size is 11.44cm within the larger 21cm circle-->
        <!-- url="data/hiro.patt" id="baseMarker" -->
        <!--ar.js has a wierd rotational offset for its "world"-->
        <!--// +x = left-->
        <!--// +y = forward-->
        <!--// +z = down-->
            <a-entity id="simulate-marker" position="0 0 -5" rotation="90 0 0"> </a-entity>

            <a-marker
                    id="marker"
                    size="0.1144"
                    listener
                    registerevents
                    smooth="${config.marker.smooth}"
                    smoothCount="${config.marker.smoothCount}"
                    smoothTolerance="${config.marker.smoothTolerance}"
                    smoothThreshold="${config.marker.smoothThreshold}"
                    type="pattern"
                    url="data/pattern-220812-BPS-QR_SQUARED_730px_67pcnt.patt" id="baseMarker"
            >
                <a-entity id="cloakRing" cloak scale="0.01 0.01 0.01" position="0 0 0" ></a-entity>
                <a-entity  id="ring"        gltf-model="#ring-model"         position="0 0.0 0" rotation="0 117 0" scale="0.01 0.01 0.01"></a-entity>
                <a-entity id="logoAnimated" gltf-model="#logoAnimated-model" animation-mixer="timeScale: 0; loop: once" rotation="0 117 0"  scale="0.01 0.01 0.01"></a-entity>
                <a-entity gltf-model="#half-sphere" position="0 -0.5 0" rotation="0 -50 0" scale="1.5 1.5 1.5"></a-entity>
                <!--important - to use the client's raw deliveries they must be rotated like this to account for the marker "world": rotation="0 94 -90"-->        
                <a-entity id="pigs" gltf-model="#pig-model" animation-mixer="timeScale: 0; loop: once" rotation="0 94 -90"></a-entity>
                <a-entity id="falcons" gltf-model="#falcon-model" animation-mixer="timeScale: 0; loop: once" rotation="0 94 -90"></a-entity>   

                <a-entity position="0 -5 0" scale="0 0 0" animation-mixer="timeScale: 0; loop: once" id="billboard-pigs" gltf-model="#billboard-pigs-model"></a-entity>   
                <a-entity position="0 -5 0" scale="0 0 0" animation-mixer="timeScale: 0; loop: once" id="billboard-falcons" gltf-model="#billboard-falcons-model"></a-entity>   

                <!-- <a-entity billboard id="pig-description-billboard" animation-mixer="timeScale: 0; loop: once" position="0 -2.5 0" rotation="-90 0 0" geometry="primitive: plane; height: ${1.45 * config.general.billboardScale}; width: ${5 * config.general.billboardScale};" material="src: #pig-description; opacity: 0; depthTest: false; depthWrite: false" ></a-entity> -->
                <!-- <a-entity billboard id="falcon-description-billboard" animation-mixer="timeScale: 0; loop: once" position="0 -2.5 0" rotation="-90 0 0" geometry="primitive: plane; height: ${1.16 * config.general.billboardScale}; width: ${5 * config.general.billboardScale};" material="src: #falcon-description; opacity: 0; depthTest: false; depthWrite: false" ></a-entity> -->
            </a-marker>
                  
            <a-entity light="type: ambient; color: #BBB"></a-entity>
            <a-entity light="type: directional; color: #FFF; intensity: 0.6" position="-0.5 1 1"></a-entity>
            <a-entity light="type: directional; color: #f7d7ab; intensity: 1.5; groundColor:  #000000; castShadow:  true" position="-1.5 -1.3 -3.5" ></a-entity>
            <a-entity light="type: point; intensity: 1.5; distance: 10; decay: 1 angle:  10; color:  #f3d2a5; castShadow: true" position="0 1.5 1"></a-entity>
            <a-light type="spot" color="#9ab0e4" intensity="1.1" penumbra="0.08" position="0 2 1" rotation="-90 0 0" target="#directionaltarget">
                <a-entity id="directionaltarget" position="0 0 -1"></a-entity>
            </a-light>    
            

            <a-entity id="debugEntity" position="0 0 -0.5" rotation="90 0 0" visible="false">
            <!--use this entity to put in content to test lighting etc. without needing to capture a marker-->
            </a-entity>
            
            <a-entity camera>
            </a-entity>

            </a-scene>
        `;
    return () => (document.getElementById("ARSCENE").innerHTML = "");
  }, []);

  return <></>;
}

export default ARScene;
