const errorMsg = (name) => {
  return `This site needs ${name} permissions to work correctly. Please reload the page and accept when asked to enable ${name} permissions.`;
};

const requestCamera = async () => {
  console.log("requesting camera...");
  await navigator.mediaDevices.getUserMedia({ video: true });
  console.log("...got camera");
};

const requestDeviceMotion = async () => {
  console.log("requesting device motion...");
  if (typeof DeviceMotionEvent.requestPermission === "function") {
    try {
      const permissionState = await DeviceMotionEvent.requestPermission();
      if (permissionState === "granted") {
        console.log("...got device motion");
      } else {
        alert(errorMsg("device motion"));
        throw "device motion";
      }
    } catch {
      alert(errorMsg("device motion"));
      throw "device motion";
    }
  } else {
    console.log("...got device motion");
  }
};

const getGps = () => {
  console.log("requesting gps...");
  if (navigator.geolocation) {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        () => {
          console.log("...got gps");
          resolve();
        },
        () => {
          console.log("...failed to get gps");
          reject("geolocation");
        }
      );
    });
  } else {
    alert(errorMsg("geolocation"));
    throw "geolocation";
  }
};

const requestGps = async () => {
  await getGps();
};

export default async function requestPermissions() {
  await requestDeviceMotion();
  await requestCamera();
  // AV11 only needs motion and camera
  // await requestGps();
}
